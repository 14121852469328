import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLock, faTruck } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import FooterMenu from '/src/components/navigation/FooterMenu'
import FooterProductMenu from '/src/components/navigation/FooterProductMenu'
import SocialList from '/src/components/social/SocialList'
import NearestStore from '/src/components/common/NearestStore'
import Header from '/src/components/layout/Header'
import HomeImageCarousel from '/src/components/carousel/HomeImageCarousel'
import { SubscribeBlock, XmasModal } from '/src/components/common'
import { LiveAgent } from '/src/components/chat'

const images = [
  /*{
    title: 'CHRISTMAS & NEW YEAR TRADING 2024',
    content: '<h1>CHRISTMAS & NEW YEAR TRADING 2024</h1><h2>WISHING YOU HAPPY AND SAFE CHRISTMAS AND NEW YEAR</h2>',
    image: "https://metalcorp-drupal-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2023-12/Orrcon-xmas-Web-Slider.jpg",
    link: {
      title: 'CHRISTMAS & NEW YEAR TRADING 2024',
      href: '/news/orrcon-steel-news/christmas24'
    }
  },*/
  {
    title: 'ONLINE ORDERING NOW AVAILABLE',
    content: '<h1>ONLINE ORDERING NOW AVAILABLE</h1><h2>CONVENIENCE REDEFINED</h2><p>Order safely & securely from any internet enabled browser</p>',
    image: "https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/EDM-Online-Shop-Convenience-redefined-1copy_145543.jpeg",
    link: {
      title: 'ONLINE ORDERING NOW AVAILABLE',
      href: '/products/all'
    }
  },
  {
    title: 'PRODUCT CATALOGUE',
    content: '<h2>PRODUCT CATALOGUE</h2><h1>MANUFACTURED & RETAIL PRODUCTS</h1><p>View & download a copy of our latest product guide</p>',
    image: "https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/orrcon-catalogue.jpeg",
    link: {
      title: 'Product Catalogue',
      href: 'https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2023-08/Orrcon-National-Product-Catalogue-LR.pdf'
    }
  },
  {
    title: 'WORKING IN PARTNERSHIP',
    content: `<h2 class="background-text">WORKING IN PARTNERSHIP</h2><h1>WE'LL SEE IT THROUGH</h1><p>Ensuring customer centricity is our number one priority</p>`,
    image: "https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/orrcon-onlineshop.jpeg"
  }
]

export default ({ children }) => {
  const elementRef = useRef()

  return (
    <div ref={elementRef} className="app">
      <Header elementRef={elementRef} />

      <div className="carousel">
        <Container fluid="xxl">
          <Row>
            <HomeImageCarousel images={images} />
          </Row>
        </Container>
      </div>

      <div className="your-rural-brand">
        <Container fluid="xxl">
          <Row>
            <Col sm={12} className='d-flex align-items-center justify-content-center'>
              <div className='content'>
                <h1 className="mt-5 mb-4">Working in Partnership</h1>
                <p>Looking for a quality Australian steel distributor and manufacturer? </p>
                <p>A business that can supply high quality steel, tube and pipe anywhere in Australia?</p>
                <p>Then you've found the right partner in Orrcon Steel.</p>
                <h2 className="mt-4 mb-4">AT ORRCON STEEL, WE'LL SEE IT THROUGH.</h2>
                <Link className="button primary outline mb-4" target="_blank" to="https://www.orrcon.com.au/sites/default/files/2022-06/OM077-OnBoarding-Welcome-Pack-LR.pdf">
                  View Our Corporate Profile
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="badges pt-5 pb-5">
            <Col md={4} className="d-flex align-items-center justify-content-center">
              <h3>
                <FontAwesomeIcon icon={faLock} />
                100% SECURE
              </h3>
            </Col>
            <Col md={4} className="d-flex align-items-center justify-content-center">
              <h3>
                <FontAwesomeIcon icon={faTruck} />
                PICKUP / DELIVERY
              </h3>
            </Col>
            <Col md={4} className="d-flex align-items-center justify-content-center">
              <h3>
                <FontAwesomeIcon icon={faTruck} />
                AUSTRALIAN OWNED
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <main>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col xs={12}>
              {children}
            </Col>
          </Row>
        </Container>
        <div className="subscribe-block-wrapper pt-5 mt-5">
          <Container fluid="xxl">
            <Row className="d-flex align-items-center mb-5">
              <Col xs={12}>
                <SubscribeBlock />
              </Col>
            </Row>
          </Container>
        </div>
      </main>

      <footer>
        <Container fluid="xxl">
          <Row className="footer-top-row d-flex align-items-center mb-4">
            <Col sm={5} md={3}>
              <h3 className="m-0">
                <a className="phone-link" href="tel:1300677266">
                  <FontAwesomeIcon icon={faPhone} />1300 677 266
                </a>
              </h3>
            </Col>
            <Col sm={7} md={6}>
              <h3 className="m-0">
                <a className="email-link" href="mailto:info@orrcon.com.au">
                  <FontAwesomeIcon icon={faEnvelope} />info@orrcon.com.au
                </a>
              </h3>
            </Col>
            <Col sm={6} md={3}>
              <SocialList />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col sm={6} md={3}>
              <FooterProductMenu />
            </Col>
            <Col sm={6} md={3}>
              <FooterMenu />
            </Col>
            <Col sm={6} md={3}>
              <NearestStore />
            </Col>
            <Col sm={6} md={3}>
              <h6 className="mb-4 text-uppercase">Head Office</h6>
              <span>121 Evans Rd, <br/>Salisbury QLD 4107</span>
            </Col>
          </Row>
        </Container>
      </footer>

      <div className="footer-bottom">
        <Container fluid="xxl">
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <div className="footer-copyright">
                <span>© Orrcon Steel · A Business of BlueScope</span>
                <ul className="list">
                  <li>
                    <a target="_blank" href="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2023-10/Orrcon%20Distribution%20Sales%20Terms%20and%20Conditions.pdf">
                      Conditions of Sale
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      Terms
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      Privacy
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <ul className="list">
                <li>
                  <StaticImage 
                    src="../../../images/paypal.svg" 
                    alt="Paypal"
                    width={72}
                    height={20}
                    placeholder="blurred"
                    layout="fixed" />
                </li>
                <li>
                  <StaticImage 
                    src="../../../images/mastercard.svg" 
                    alt="Mastercard"
                    width={54}
                    height={42}
                    placeholder="blurred"
                    layout="fixed" />
                </li>
                <li>
                  <StaticImage 
                    src="../../../images/visa.svg"
                    alt="Visa"
                    width={56}
                    height={20}
                    placeholder="blurred"
                    layout="fixed" />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <LiveAgent />
    </div>
  )
}
